<template>
  <div id="ourS">
    <v-container class="ou">
      <v-container class="os">
        <v-row class="pa-5">
          <v-col cols="12" md="8">
            <div class="mt-15">
              <h1>{{ head1_1 }}</h1>
              <h1>{{ head1_2 }}</h1>
              <h2 class="mt-10"> {{ head2_1 }}</h2>
              <h2 class="mt-10"> {{ head2_2 }}</h2>
              <h2 class="mt-10"> {{ head2_3 }}</h2>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              style="position: relative"
              src="@/assets/assetsWice/about.png"
              max-height="691"
              max-width="518"
            ></v-img>
            <!-- <v-img src="@/assets/1.png" max-height="70"></v-img> -->
            <!-- <v-row>
            <v-col cols="1" ls="10" md="10">
              <v-row align="start">
                <v-img  src="@/assets/1.png" max-height="70" ></v-img>
              <h2>DOAJ Indexing Assistance</h2> </v-row>
              <v-row align="start">
                <v-img  src="@/assets/1.png" max-height="70" ></v-img>
              <h2>DOAJ Indexing Assistance</h2> </v-row>
  
              <v-img src="@/assets/2.png" max-height="70"></v-img>
              <v-img src="@/assets/3.png" max-height="70"></v-img>
              <v-img src="@/assets/4.png" max-height="70"></v-img>
              <v-img src="@/assets/5.png" max-height="70"></v-img>
              <v-img src="@/assets/6.png" max-height="70"></v-img>
            </v-col>
            <v-col cols="2" ls="10" md="10">
            <h2>Research Program and Study Center</h2>
            <h2>Develop Open Journal System</h2>
            <h2>Data Processing</h2>
            <h2>Turnitin and IThenticate</h2>
            <h2>Parafrase and Copyediting</h2>
            </v-col>
            
  
            </v-row>-->
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
  
  <script>
  // import axios from 'axios'
export default {
// mounted() {
//   axios.get('http://warmadewa.vikramastrategis.com/apps/auth/sigin')
//   .then(response => console.log(response))
// },

  data() {
    return {
      head1_1: "Expand your horizons at the",
      head1_2: "Economic Conference of the year",
      head2_1:
        "Welcome to the 3rd Warmadewa International Conference of Economic 2023 (WICE). Taking place on 05 07-10-2023 in Warmadewa University, Denpasar, Bali, Indonesia, a Economic conference is more than an academic conference. It’s an opportunity to source feedback on your research, To provide a platform for participants to present their research findings and share knowledge relating to Green Economy & Sustainable Growth.",
        head2_2: "We invite you to join us for three days of learning and networking. You are guaranteed to leave the event with a suitcase full of knowledge and inspiration. This is a unique opportunity to understand the challenges your peers are facing and come up with creative solutions.",
        head2_3: "See you in Bali!",
    };
  }
};
</script>
  
  <style scoped>
.v-container.ou {
  background: #ffffff;
  max-width: 100%;
}

.v-container.os {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.v-container.os {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

h1 {
  font-size: 40px;
}

h2 {
    font-weight: normal;
  font-size: 16px;
  color: #5D5D5D;
  text-align: justify;
  text-justify: inter-word;
}

.v-img {
  border-radius: 7%;
}
</style>