<template>
  <div class="napbar">
    <div class="navdraw">
      <v-navigation-drawer
        v-model="drawer"
        class="bg-grey-lighten-5"
        app
        temporary
        theme="light"
        src="favicon.ico"
        location="left"
      >
        <v-list>
          <v-list-item>
            <v-list-item class="text-center"> </v-list-item>
            <v-list-item-content class="text-center">
              <v-list-item-title class="title" style="color: #fcd800"
                >WICE</v-list-item-title
              >
              <v-list-item-subtitle style="color: #035295"
                >WARMADEWA</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list class="navlis" dense>
          <v-list-item class="text-center">
            <router-link to="/" custom v-slot="{ navigate }">
              <v-btn @click="navigate" role="link">HOME</v-btn>
            </router-link>
            <br />
            <br />

            <v-menu open-on-click>
              <template v-slot:activator="{ props }">
                <v-btn class="text-black" v-bind="props">INFORMATION</v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link to="/about" custom v-slot="{ navigate }">
                    <v-btn
                      class="a text-black"
                      variant="plain "
                      @click="navigate"
                      role="link"
                      >About Conference</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/important" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Important Dates</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/scientific" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Scientific Committee</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/conference" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Conference Speakers</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/paperpub" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Paper Publication Opportunity</v-btn
                    >
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
            <br />
            <br />
            <v-menu open-on-click>
              <template v-slot:activator="{ props }">
                <v-btn class="text-black" v-bind="props">AUTHOR GUIDLINES</v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link to="/callforpaper" custom v-slot="{ navigate }">
                    <v-btn
                      class="a text-black"
                      variant="plain "
                      @click="navigate"
                      role="link"
                      >Call For Papers</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/abstract" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Abstract
                      Format</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/fullpaper" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Full Paper
                      Format</v-btn
                    >
                  </router-link>
                  <br />
                  <router-link to="/poster" custom v-slot="{ navigate }">
                    <v-btn
                      variant="plain"
                      class="a text-black"
                      @click="navigate"
                      role="link"
                      >Poster Format</v-btn
                    >
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
            <br />
            <br />
            <router-link to="venue" custom v-slot="{ navigate }">
              <v-btn @click="navigate" role="link">VENUE</v-btn>
            </router-link>
            <br />
            <br />
            <router-link to="/history" custom v-slot="{ navigate }">
              <v-btn @click="navigate" role="link">HISTORY</v-btn>
            </router-link>
            <br />
            <br />
            <router-link to="/contact" custom v-slot="{ navigate }">
              <v-btn @click="navigate" role="link">CONTACT</v-btn>
            </router-link>
            <br />
            <br />
            <router-link to="/login" custom v-slot="{ navigate }">
              <v-btn class="hiji" @click="navigate" role="link"
                >LOGIN/REGISTER</v-btn
              >
            </router-link>
            <br />
            <br />
            <!-- <router-link to="/" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link"> Home </v-btn>
          </router-link>-->

            <br />

            <br />
            <br />
            <!-- <router-link to="/about" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link"> About Us </v-btn>
          </router-link>-->
            <br />
            <!-- <br />
            <router-link to="/stories" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link"> Stories and Visit </v-btn>
          </router-link>
          <br />-->
            <br />
            <!-- <v-btn class="text-black" text @click="scroll('footer')">Contact</v-btn> -->
            <v-list-item-content>
              <v-list-item-title class="subtitile-1">
                {{ text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div class="apbar">
      <v-app-bar dark flat class="px 12">
        <v-img
          class="mx-10"
          src="@/assets/logo/logoWice.png"
          max-height="125"
          max-width="125"
        ></v-img>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="mr-4 text-black"
          v-if="isXs"
        />

        <div v-else>
          <router-link to="/" custom v-slot="{ navigate }">
            <v-btn @click="navigate" role="link">HOME</v-btn>
          </router-link>
          <!-- <v-btn text @click="scroll('#HomeView')" class="text-white">Home</v-btn> -->
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props">INFORMATION</v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link to="about" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> About
                    Conference</v-btn
                  >
                </router-link>
                <router-link to="important" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Important
                    Dates</v-btn
                  >
                </router-link>
                <router-link to="scientific" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Scientific
                    Committee</v-btn
                  >
                </router-link>
                <br />
                <router-link to="conference" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Conference
                    Speakers</v-btn
                  >
                </router-link>
                <router-link to="paperpub" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Paper
                    Publication Opportunity</v-btn
                  >
                </router-link>
                <!-- <a href="about">
                <h1><v-icon icon="mdi-chevron-right"></v-icon> About Conference</h1>
              </a>
              <a href="important">
                <h1><v-icon icon="mdi-chevron-right"></v-icon> Important Dates</h1>
              </a>
              <a href="">
                <h1><v-icon icon="mdi-chevron-right"></v-icon> Scientific Committee</h1>
              </a>
              <a href="">
                <h1><v-icon icon="mdi-chevron-right"></v-icon> Conference Speakers</h1>
              </a>
              <a href="">
                <h1><v-icon icon="mdi-chevron-right"></v-icon> Paper Publication Opportunity</h1>
              </a> -->
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props">AUTHOR GUIDLINES</v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link to="callforpaper" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Call for
                    paper</v-btn
                  >
                </router-link>
                <router-link to="abstract" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Abstract
                    Format</v-btn
                  >
                </router-link>
                <router-link to="fullpaper" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Full Paper
                    Format</v-btn
                  >
                </router-link>
                <br />
                <router-link to="poster" custom v-slot="{ navigate }">
                  <v-btn variant="plain" @click="navigate" role="link"
                    ><v-icon icon="mdi-chevron-right"></v-icon> Poster
                    Format</v-btn
                  >
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link to="venue" custom v-slot="{ navigate }">
            <v-btn @click="navigate" role="link">VENUE</v-btn>
          </router-link>
          <router-link to="history" custom v-slot="{ navigate }">
            <v-btn @click="navigate" role="link">HISTORY</v-btn>
          </router-link>
          <router-link to="contact" custom v-slot="{ navigate }">
            <v-btn @click="navigate" role="link">CONTACT</v-btn>
          </router-link>
          <router-link to="/login" custom v-slot="{ navigate }">
            <v-btn class="hiji" @click="navigate" role="link"
              >LOGIN/REGISTER</v-btn
            >
          </router-link>
        </div>
      </v-app-bar>
    </div>
    <!-- <v-app-bar
        app
        color=""
        elevation=""
        hide-on-scroll
        prominent
        scroll-target="#scrolling-techniques-4"
        dark
        class="px-15"
        flat
      >
        <v-toolbar-title>
          <v-img src="@/assets/ksiputih.png" max-width="100px" />
        </v-toolbar-title>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="mr-4 text-white"
          v-if="isXs"
        />
        <div v-else>
          <router-link to="/" custom v-slot="{ navigate }">
            <v-btn class="text-white" @click="navigate" role="link"> Home </v-btn>
          </router-link>
          <v-btn text @click="scroll('how')" class="text-white">Service</v-btn>
          <v-btn text @click="scroll('home')" class="text-white">About Us</v-btn>
          <v-btn text @click="scroll('home')" class="text-white">Contact</v-btn>
          
        </div>
    </v-app-bar>-->
  </div>

  <!-- <v-btn class="text-white" text @click="$vuetify.goTo('home')">
            <span class="mr-2">Home</span>
          </v-btn>
          <v-btn class="text-white" text @click="$vuetify.goTo('#features')">
            <span class="mr-2">About</span>
          </v-btn>
          <v-btn class="text-white" text @click="$vuetify.goTo('#download')">
            <span class="mr-2">Service</span>
          </v-btn>
          <v-btn class="text-white" text @click="$vuetify.goTo('#pricing')">
            <span class="mr-2">Contact Us</span>
  </v-btn>-->

  <!-- <div id="navbar">
      <div class="nav-brand">
        <h2>W3hubs</h2>
        <i class="fa fa-navicon" v-on:click="display"></i>
      </div>
  
      <div class="bar-items" :class="{ hide: !show }">
        <div id="menu">
  
  
  
          <RouterLink to="/home">
            Home
          </RouterLink>
  
  
          <RouterLink to="/about">
            About
          </RouterLink>
  
  
          <RouterLink to="/servie">
            Service
          </RouterLink>
  
  
          <RouterLink to="/contact">
            Contact Us
          </RouterLink>
        </div>
      </div>
  
  </div>-->
  <!-- <body>
    <nav class="navbar">
      <div class="logo">MUO</div>
  
  
      <ul class="nav-links">
  
        <input type="checkbox" id="checkbox_toggle" />
  
        <label for="checkbox_toggle" class="hamburger">&#9776;</label>
  
        <div class="menu">
          <li><a href="/">Home</a></li>
  
          <li><a href="/">About</a></li>
  
          <li class="services">
            <a href="/">Services</a>
  
  
            <ul class="dropdown">
              <li><a href="/">Dropdown 1 </a></li>
  
              <li><a href="/">Dropdown 2</a></li>
  
              <li><a href="/">Dropdown 2</a></li>
  
              <li><a href="/">Dropdown 3</a></li>
  
              <li><a href="/">Dropdown 4</a></li>
            </ul>
          </li>
  
          <li><a href="/">Pricing</a></li>
  
          <li><a href="/">Contact</a></li>
        </div>
      </ul>
    </nav>
  </body>-->

  <!-- <v-app-bar color="#1C6758" dark flat class="px 12">
          <v-img
          
      class="mx-10"
      src="../assets/ksiputih.png"
      max-height="100"
      max-width="100"
    ></v-img>
    <v-btn text @click="scroll('#HomeView')" class="text-white">Home</v-btn>
    <v-btn text @click="scroll('how')" class="text-white">Service</v-btn>
    <v-btn text @click="scroll('home')" class="text-white">About Us</v-btn>
    <v-btn text @click="scroll('home')" class="text-white">Contact</v-btn>
    <v-spacer></v-spacer>
    <v-btn text @click="scroll('home')" class="text-white">English</v-btn>
  </v-app-bar>-->
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
a:link {
  text-decoration: none;
}

a {
  color: #011e6e;
  font-size: 9px;
}
div.napbar .apbar {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 80px;
}
.v-app-bar {
  background: #f9f9f9;
}
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.span {
  color: #333;
}

.v-btn:hover {
  color: #011e6e;
}

.v-btn:focus {
  color: #333;
}

.v-btn:active {
  color: #333;
}
.v-btn {
  text-transform: none !important;
  color: #011e6e;
  font-size: 12px;
}
</style>
