<template>
  <div class="about">
    <v-app>
      <NavBarwice/>
      <AboutConf/>
      <ExpandYour/>
      <AddWice/>
      <ConferenceThemes/>
      <PaperPublication/>
      <IndexingWice/>
      <ContactUs/>
      <FooterWice/>
    </v-app>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import NavBarwice from "@/components/NavBarwice.vue";
import AboutConf from "@/components/information/AboutConf.vue";
import ExpandYour from '@/components/ExpandYour.vue';
import AddWice from '@/components/AddWice.vue';
import ConferenceThemes from '@/components/ConferenceThemes.vue';
import PaperPublication from '@/components/PaperPublication.vue';
import IndexingWice from '@/components/IndexingWice.vue';
import ContactUs from "@/components/ContactUs.vue";
import FooterWice from "@/components/FooterWice.vue";


export default defineComponent ({
    components: { 
      NavBarwice,
      AboutConf,
      ExpandYour,
      AddWice,
      ConferenceThemes,
      PaperPublication,
      IndexingWice,
      ContactUs,
    FooterWice }
});
</script>

<style>

</style>
