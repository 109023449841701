<template>
  <v-app>
    <!-- <NavBarwice /> -->
      <router-view/>
  </v-app>
</template>

<script>
// import NavBarwice from '@/components/NavBarwice.vue';


export default {
  name: 'App',

  components: {
    // NavBarwice,
  }
}
</script>
