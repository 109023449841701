<template>
  <div id="hero" class="pt-10">
    <v-container class="hh">
      <v-container class="he">
        <v-row class="pt-0 text-center">
          <v-col cols="12" md="12">
            <div class="ma-0 animate__animated animate__backInLeft">
              <h1 class="a pt-0">
                {{ tanggal }}
                <a style="color:#FCD800">3rd</a>
                {{ head1 }}
              </h1>
              <h1 class="a">" {{ head2 }} "</h1>

              <!-- <h1 class="a"> {{ head3 }}</h1>
              <h4>{{ subHead }}</h4>
              <br />
              <v-btn v-bind:href="linkWA" variant="outlined" rounded>Contact Us</v-btn>-->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
  
  <script>
import imgHero from "@/assets/assetsWice/hero.png";

export default {
  data() {
    return {
      tanggal: "6 - 7 October 2023",
      head1: " Warmadewa International Conference of Economic 2023",
      head2:
        "GREEN ECONOMY ACCELERATION: A STRATEGY FOR SUSTAINABLE GROWTH",
      subHead:
        "National Journal Publication Solutions (ISSN and SINTA Accredited) and Reputable International Journals",
      linkWA: "https://wa.link/3bn2e2",
      imgHero
    };
  }
};
</script>
  
  <style scoped>
.a {
  font-size: 38px;
  color: #ffffff;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
}
h4 {
  font-size: 1vw;
  color: rgb(3, 3, 3);
  /* background-image: url("@/assets/logo.svg"); */
}
@media (max-width: 991px) {
  .a {
    font-size: 20px;
    color: #ffffff;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  h4 {
    font-size: 1rem;
    color: rgb(14, 13, 13);
    /* background-image: url("@/assets/logo.svg"); */
  }
}

.v-container.hh {
  background: url("@/assets/assetsWice/hero.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 788px;
  max-width: 100%;
}
.v-container.he {
  width: 1006px;
  height: 300px;
  margin-top: 191px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.9px);
  -webkit-backdrop-filter: blur(7.9px);
  border: 1px solid rgba(255, 255, 255, 0);
  max-width: 100%;
}

.v-container.he {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.he {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.he {
    max-width: 1281px;
  }
}
.v-btn:hover {
  background-color: #b10801;
  color: #ffffff;
}

.v-btn:focus {
  background-color: #d6cda4;
}

.v-btn:active {
  background-color: #d6cda4;
}
.v-btn {
  color: #b10801;
  text-transform: none !important;
}
</style>