<template>
  <div id="footer">
    <footer class="site-footer">
      <v-container class="fo">
        <v-row class="mb-1" no-gutters>
          <v-col cols="12" md="4" class="pa-5">
            <div>
              <h1>{{title1}}</h1>
              <hr class="wan" />
              <br />
              <h2 class="won">{{ content1 }}</h2>
            </div>
          </v-col>
          <v-col cols="12" md="2" class="pa-5">
            <div>
              <h1>{{ title2 }}</h1>
              <hr class="wan" />
              <br />
              <div class="ml-3">
                <h2>{{ link1 }}</h2>
                <br />
                <h2>{{ link2 }}</h2>
                <br />
                <h2>{{ link3 }}</h2>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="pa-5">
            <div>
              <h1>{{ title3 }}</h1>
              <hr class="wan" />
              <br />
            
                <v-text-field
            label="Subscribe to our newslatter"
            placeholder=""
            variant="outlined"
          ></v-text-field>
          <v-text-field
            label="Email"
            placeholder=""
            variant="outlined"
          ></v-text-field>
          <v-btn v-bind:href="linkWA" variant="outlined" rounded>Subsribe</v-btn>
                <!-- <v-sheet width="300" class="mx-auto">
                  <v-form fast-fail @submit.prevent>
                    <v-text-field
            label="Your landing page"
            variant="plain"
          ></v-text-field>
          <v-text-field
            label="Your landing page"
            variant="plain"
          ></v-text-field>

                    <v-btn type="submit" block class="mt-2">Subscribe</v-btn>
                  </v-form>
                </v-sheet> -->
                <br />
              
            </div>
          </v-col>
          <v-col cols="12" md="3" class="pa-5">
            <div>
              <h1>{{ title4 }}</h1>
              <hr class="wan" />
              <br />
              <div class="ml-0">
                <v-row>
                  <v-col cols="1" md="1">
                    <h2>
                      <v-icon>mdi-email-outline</v-icon>
                    </h2>
                  </v-col>
                  <a href="mailto:info@wiceunwar.com">
                  <v-col cols="10" md="10">
                    <h2 class="ml-3">{{ email }}</h2>
                  </v-col></a>
                </v-row>
                <v-row>
                  <v-col cols="1" md="1">
                    <h2>
                      <v-icon>mdi-phone-classic</v-icon>
                    </h2>
                  </v-col>
                  <v-col cols="10" md="10">
                    <h2 class="ml-3">{{ telpon1}}</h2>
                    <h2 class="ml-3">{{ telpon2}}</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <a v-bind:href="fb" target="_blank">
                      <v-img src="@/assets/logo/fb.png"></v-img>
                    </a>
                  </v-col>
                  <v-col cols="3" md="3">
                    <a v-bind:href="ig" target="_blank">
                      <v-img src="@/assets/logo/ig.png"></v-img>
                    </a>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>
      
<script>
export default {
  data() {
    return {
      title1: "About WICE",
      title2: "Useful Links",
      title3: "Newsletter",
      title4: "Contact Us",
      content1:
        "Welcome to the 3rd Warmadewa International Conference of Economic 2023 (WICE). Taking place on 05-07 10 2023 in Bali, Indonesia, a Economic conference is more than an academic conference. It’s an opportunity to source feedback on your research, to get published in internationally recognized academic journals, and to explore the city of Bali on a free tour.",
      link1: "CALL FOR PAPER",
      link2: "ABSTRACT FORMAT",
      link3: "FULL PAPER FORMAT",
      email: "info@wiceunwar.com",
      telpon1: "+6287862894657",
      telpon2: "+6287861842622",
      fb: "https://www.facebook.com/profile.php?id=100094408639089",
      ig: "https://www.instagram.com/wiceunwar/?igshid=MzRlODBiNWFlZA%3D%3D"
    };
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>
    
      <style scoped>
html {
  scroll-behavior: smooth;
}

.v-container.fo {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.fo {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.fo {
    max-width: 1281px;
    /* padding-left: 170px;
      padding-right: 170px;
      padding-top: 50px; */
  }
}

/* .v-text-field {
  border-radius: 10px;
  border-style: solid;
  background-color: #b6c1ca;
  padding: 3px;
  border-color: #1480b6;
  border-width: 2px;
  color: #ffffff;
  font-size: 1px;
} */

.v-img {
  border-radius: 55%;
  width: 40px;
  height: 40px;
}

.v-img:hover {
  box-shadow: 1px 8px 20px #fffdfd;
  -webkit-transition: box-shadow 0.3s ease-in;
}

.v-sheet {
  background-color: #035295;
  color: #ffffff;
}

.v-btn {
    width: 555px;
  height: 40px;
  border-radius: 20px;
  background-color: #FCD800;
  color: #035295;
  text-transform: none !important;
}
hr.wan {
  width: 70px;
  border: 1px solid #fcd800;
}

a {
  text-decoration: none;
}

h1 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  /* font-style: normal;
  font-weight: 400; */
}

h2.won {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: justify;
}

h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.site-footer {
  background: #035295;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}
</style>