<template>
  <div id="ourJ">
    <v-container class="oo pb-16">
      <v-container class="oj">
        <v-row class="pa-8 mr-0 ml-0">
          <v-col cols="12" md="10" class="pb-5">
            <h1> {{ head1 }} </h1>
            <h1 class="mb-5"> {{ head2 }} </h1>
            <h2> {{ subHead1 }}</h2>
          </v-col>
          <v-col cols="12" md="2" class="pt-10">
            <v-btn v-bind:href="linkWA" variant="outlined" rounded>{{ action }} <v-icon style="margin-left: 5px; font-size: 20px" icon="mdi-rocket-launch-outline"></v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      head1: "If you are interested in attending the conference and getting very useful ",
      head2: "feedback, then register yourself",
      subHead1: "We will call you within 24 Hours",
      action: "Contact us",
      linkWA: "https://api.whatsapp.com/send/?phone=6287862894657&text&type=phone_number&app_absent=0"
    };
  }
};
</script>
  
  <style scoped>
.v-container.oo {
  background: #ffffff;
  max-width: 100%;
}
.v-container.oj {
  background: #035295;
  border-radius: 30px;
  max-width: 100%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 15px 15px 0px rgba(0, 0, 0, 0.09),
    0px 34px 21px 0px rgba(0, 0, 0, 0.05), 0px 61px 24px 0px rgba(0, 0, 0, 0.01),
    0px 96px 27px 0px rgba(0, 0, 0, 0);
}

.v-container.oj {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oj {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oj {
    max-width: 1281px;
  }
}

h1 {
  color: #fff;
  font-size: 18px;
  /* font-style: normal; */
}

h2 {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  margin-bottom: 5px;
}

.v-btn:hover {
  background-color: #FCD800;
  color: #ffffff;
}

/* .v-btn:focus {
  background-color: #d6cda4;
}

.v-btn:active {
  background-color: #d6cda4;
} */
.v-btn {
    width: 555px;
  height: 40px;
  border-radius: 20px;
  background-color: #FCD800;
  color: #035295;
  text-transform: none !important;
}

/* .v-img {
      border-radius: 10%;
    } */
</style>