<template>
  <div id="ourS">
    <v-container class="ou">
      <v-container class="os">
        <v-row class="pa-5">
          <v-col cols="12" md="7">
            <div class="mt-15">
              <h1>{{ title }}</h1>
              <h2 class="mt-7">{{ head1_1 }}</h2>
              <h2 class="mt-1">{{ head1_2 }}</h2>
              <h2 class="mt-1">{{ head1_3 }}</h2>
              <h2 class="mt-1">{{ head1_4 }}</h2>
              <h2 class="mt-1">{{ head1_5 }}</h2>
              <h2 class="mt-1">{{ head1_6 }}</h2>
              <h2 class="mt-1">{{ head1_7 }}</h2>
              <h2 class="mt-1">{{ head1_8 }}</h2>
              <h2 class="mt-1">{{ head1_9 }}</h2>
              <h2 class="mt-1">{{ head1_10 }}</h2>
              <h2 class="mt-1">{{ head1_11 }}</h2>
              <h2 class="mt-1">{{ head1_12 }}</h2>
              <h2 class="mt-1">{{ head1_13 }}</h2>
              <h2 class="mt-1">{{ head1_14 }}</h2>
              <h2 class="mt-1">{{ head1_15 }}</h2>
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <v-img
              style="position: relative"
              src="@/assets/assetsWice/topic.png"
              max-width="599.927"
              max-height="400"
            ></v-img>
            <!-- <v-img src="@/assets/1.png" max-height="70"></v-img> -->
            <!-- <v-row>
              <v-col cols="1" ls="10" md="10">
                <v-row align="start">
                  <v-img  src="@/assets/1.png" max-height="70" ></v-img>
                <h2>DOAJ Indexing Assistance</h2> </v-row>
                <v-row align="start">
                  <v-img  src="@/assets/1.png" max-height="70" ></v-img>
                <h2>DOAJ Indexing Assistance</h2> </v-row>
    
                <v-img src="@/assets/2.png" max-height="70"></v-img>
                <v-img src="@/assets/3.png" max-height="70"></v-img>
                <v-img src="@/assets/4.png" max-height="70"></v-img>
                <v-img src="@/assets/5.png" max-height="70"></v-img>
                <v-img src="@/assets/6.png" max-height="70"></v-img>
              </v-col>
              <v-col cols="2" ls="10" md="10">
              <h2>Research Program and Study Center</h2>
              <h2>Develop Open Journal System</h2>
              <h2>Data Processing</h2>
              <h2>Turnitin and IThenticate</h2>
              <h2>Parafrase and Copyediting</h2>
              </v-col>
              
    
            </v-row>-->
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      title: "Conference Themes and Topics",
      head1_1: "• Sustainable Infrastructure: Building the Foundation for a Green Economy",
      head1_2:
        "• Renewable Energy Transition: Driving Economic Growth and Environmental Sustainability",
      head1_3:
        "• Circular Economy: Redefining Consumption and Production for a Sustainable Future",
      head1_4: "• Green Innovation and Entrepreneurship: Fueling Economic Opportunities",
      head1_5: "• Sustainable Agriculture and Food Systems: Nurturing the Green Economy",
      head1_6: "• Sustainable Transportation: Paving the Way for Eco-friendly Mobility",
      head1_7: "• Green Finance and Investment: Catalyzing Sustainable Growth",
      head1_8: "• Policy and Governance for a Greener Economy: Enabling Transition and Transformation",
      head1_9: "• Green Jobs and Skills: Empowering the Workforce for a Sustainable Future",
      head1_10: "• Sustainable Cities and Communities: Designing Urban Spaces for Green Living",
      head1_11: "• Concepts and Principles of the Green Economy",
      head1_12: "• Developing Green Infrastructure",
      head1_13: "• Sustainable Resource Management",
      head1_14: "• Green Technology Innovations for Economic Growth",
      head1_15: "• Public Policies and Regulations Driving the Green Economy",
    };
  }
};
</script>
    
    <style scoped>
.v-container.ou {
  background: #ffffff;
  max-width: 100%;
}

.v-container.os {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.v-container.os {
  padding: 10px;
}

.v-img {
  margin-top: 170px;
  border-radius: 5%;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

h1 {
  color: #035295;
  font-size: 25px;
}

h2 {
  font-weight: normal;
  font-size: 16px;
  color: #5d5d5d;
}
</style>