<template>
  <div id="ourJ">
    <v-container class="oo">
      <v-container class="oj">
        <div class="title text-center pt-5">
          <h1>{{ title }}</h1>
          <br />
        </div>
        <v-row justify="center">
          <v-col cols="6" md="3" lg="3" class="pa-0">
            <v-card
              flat
              class="pa-5 text-center rounded-xl"
            >
              <v-img
                class="mx-auto pb-5"
                style="position: relative"
                src="@/assets/icon/addIcon1.png"
                max-height="80"
                max-width="80"
              ></v-img>
              <h2>{{ date1 }}</h2>
              <h3>{{ subtitle1 }}</h3>
              <h3>{{ action1 }}</h3>
            </v-card>
          </v-col>
          <v-col cols="6" md="3" lg="3" class="pa-0">
            <v-card
              flat
              class="pa-5 text-center rounded-xl"
            >
              <v-img
                class="mx-auto pb-5"
                style="position: relative"
                src="@/assets/icon/addIcon2.png"
                max-height="80"
                max-width="80"
              ></v-img>
              <h2>{{ date2 }}</h2>
              <h3>{{ subtitle2 }}</h3>
              <h3>{{ action2 }}</h3>
            </v-card>
          </v-col>
          <v-col cols="6" md="3" lg="3" class="pa-0">
            <v-card
              flat
              class="pa-5 text-center rounded-xl"
            >
              <v-img
                class="mx-auto pb-5"
                style="position: relative"
                src="@/assets/icon/addIcon3.png"
                max-height="80"
                max-width="80"
              ></v-img>
              <h2>{{ date3 }}</h2>
              <h3>{{ subtitle3 }}</h3>
              <h3>{{ action3 }}</h3>
            </v-card>
          </v-col>
          <v-col cols="6" md="3" lg="3" class="pa-0">
            <v-card
              flat
              class="pa-5 text-center rounded-xl"
            >
              <v-img
                class="mx-auto pb-5"
                style="position: relative"
                src="@/assets/icon/addIcon4.png"
                max-height="80"
                max-width="80"
              ></v-img>
              <h2>{{ date4 }}</h2>
              <h3>{{ subtitle4 }}</h3>
              <h3>{{ action4 }}</h3>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      title: "Add WICE to your Economic conferences calendar",
      date1: "16-09-2023",
      date2: "27-09-2023",
      date3: "23-09-2023",
      date4: "27-09-2023",
      subtitle1: "Abstrac Submission",
      subtitle2: "Presenter Fee Payment",
      subtitle3: "Full Paper",
      subtitle4: "Admission Payment",
      action1: "Deadline",
      action2: "Deadline",
      action3: "Submission Deadline",
      action4: "Deadline"
    };
  }
};
</script>
  
  <style scoped>
.v-container.oo {
  background: #035295;
  max-width: 100%;
}
.v-container.oj {
  background: #035295;
  max-width: 100%;
  /* margin-left: auto;
    margin-right: auto; */
  /* padding-right: 100px;
    padding-left: 100px; */
}

.v-container.oj {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oj {
    max-width: 1000px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oj {
    max-width: 950px;
  }
}

h1 {
  color: #fff;
  font-size: 35px;
  margin-bottom: 5px;
}

h2 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
}

h3 {
  font-weight: normal;
  color: #fff;
  font-size: 15px;
}

.v-card {
  background: #035295;
  width: 204px;
  height: 220px;
}

.v-card:hover {
  box-shadow: 1px 8px 20px #1b79cc;
  -webkit-transition: box-shadow 0.3s ease-in;
}

/* .v-img {
  border-radius: 10%;
} */
</style>