<template>
  <div id="ourJ">
    <v-container class="oo pb-16">
      <v-container class="oj">
        <v-row class="mt-10 mr-6 ml-6" justify="center">
          <v-img src="@/assets/indexing/1garuda.png"></v-img>
          <v-img src="@/assets/indexing/2google.png"></v-img>
          <v-img src="@/assets/indexing/3doi.png"></v-img>
          <v-img src="@/assets/indexing/4copernicus.png"></v-img>
          <v-img src="@/assets/indexing/5road.png"></v-img>
          <v-img src="@/assets/indexing/6isjd.png"></v-img>
        </v-row>
        <v-row class="mt-10 mr-5 ml-5" justify="center">
          <v-img src="@/assets/indexing/7wizdom.png"></v-img>
          <v-img src="@/assets/indexing/8oneSearch.png"></v-img>
          <v-img src="@/assets/indexing/9ebsco.png"></v-img>
          <v-img src="@/assets/indexing/10doaj.png"></v-img>
          <v-img src="@/assets/indexing/11publons.png"></v-img>
          <v-img src="@/assets/indexing/12sienceOpen.png"></v-img>
        </v-row>
        <v-row class="mt-10 mr-5 ml-5" justify="center">
          <v-img src="@/assets/indexing/13orcid.png"></v-img>
          <v-img src="@/assets/indexing/14dimensions.png"></v-img>
          <v-img src="@/assets/indexing/15econstor.png"></v-img>
          <v-img src="@/assets/indexing/16caspa.png"></v-img>
          <v-img src="@/assets/indexing/17ezb.png"></v-img>
          <v-img src="@/assets/indexing/18plumx.png"></v-img>
        </v-row>
        <v-row class="mt-10 mb-10" justify="center">
          <v-img src="@/assets/indexing/19hein.png"></v-img>
          <v-img src="@/assets/indexing/20abcd.png"></v-img>
          <v-img src="@/assets/indexing/21cabi.png"></v-img>
          <v-img src="@/assets/indexing/22sinta.png"></v-img>
          <v-img src="@/assets/indexing/23open.png"></v-img>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.oo {
  background: #ffffff;
  max-width: 100%;
}
.v-container.oj {
  background: #035295;
  border-radius: 30px;
  max-width: 100%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 15px 15px 0px rgba(0, 0, 0, 0.09), 0px 34px 21px 0px rgba(0, 0, 0, 0.05), 0px 61px 24px 0px rgba(0, 0, 0, 0.01), 0px 96px 27px 0px rgba(0, 0, 0, 0.00);
}

.v-container.oj {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oj {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oj {
    max-width: 1281px;
  }
  .v-row {
  margin-left: 130px;
  margin-right: 130px;
}
}

h1 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 5px;
}

h2 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
}


.v-img {
  width: 150px;
  height: 70px;
  /* border-radius: 100px; */
}

.v-btn {
  width: 555px;
  height: 40px;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-top: 15px;
}

.v-card {
  background: #035295;
  width: 204px;
  height: 220px;
}

.v-card:hover {
  box-shadow: 1px 8px 20px #1b79cc;
  -webkit-transition: box-shadow 0.3s ease-in;
}

/* .v-img {
    border-radius: 10%;
  } */
</style>