<template>
  <div id="ourJ">
    <v-container class="oo">
      <v-container class="oj">
        <div class="title text-center pt-5">
          <h1>{{ title }}</h1>
          <br />
        </div>
        <v-row >
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5">
            <v-img class="mx-auto" style="position: relative" src="@/assets/speaker/1iMadeSare.png"></v-img>
            
          </v-col>
          <v-col cols="12" md="9" lg="9" class="pa-0 mb-5">
            <div>
            <h2>{{ name1 }}</h2>
            <h3>{{ univ1 }}</h3>
          </div>
          </v-col>
        </v-row>
        <hr/>
        <v-row class="pt-5">
          <v-col cols="12" md="3" lg="3" class="pt-0 mb-5">
            <v-img class="mx-auto " style="position: relative" src="@/assets/speaker/2lawSiong.png"></v-img>
          </v-col>
          <v-col cols="12" md="9" lg="9" class="pa-0 mb-5">
            <div>
            <h2>{{ name2 }}</h2>
            <h3>{{ univ2 }}</h3>
          </div>
          </v-col>
        </v-row>
        <hr/>
        <v-row class="pt-5">
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5">
            <v-img class="mx-auto " style="position: relative" src="@/assets/speaker/3ciorstan.png"></v-img>
            
          </v-col>
          <v-col cols="12" md="9" lg="9" class="pa-0 mb-5">
            <div>
            <h2>{{ name3 }}</h2>
            <h3>{{ univ3 }}</h3>
          </div>
          </v-col>
        </v-row>
        <hr/>
        <v-row class="pt-5">
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5">
            <v-img class="mx-auto " style="position: relative" src="@/assets/speaker/4mhd.png"></v-img>
            
          </v-col>
          <v-col cols="12" md="9" lg="9" class="pa-0 mb-5">
            <div>
            <h2>{{ name4 }}</h2>
            <h3>{{ univ4 }}</h3>
          </div>
          </v-col>
        </v-row>
        <!-- <v-row justify="center">
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5 text-center">
            <v-img class="mx-auto pb-5" style="position: relative" src="@/assets/assetsWice/5.png"></v-img>
            <h2>{{ date1 }}</h2>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5 text-center">
            <v-img class="mx-auto pb-5" style="position: relative" src="@/assets/assetsWice/5.png"></v-img>
            <h2>{{ date1 }}</h2>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5 text-center">
            <v-img class="mx-auto pb-5" style="position: relative" src="@/assets/assetsWice/5.png"></v-img>
            <h2>{{ date1 }}</h2>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="pa-0 mb-5 text-center">
            <v-img class="mx-auto pb-5" style="position: relative" src="@/assets/assetsWice/5.png"></v-img>
            <h2>{{ date1 }}</h2>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="space-around">
          <router-link to="conference" custom v-slot="{ navigate }">
                  <v-btn color="#F8DA00" rounded @click="navigate" role="link">View All Conference Speakers</v-btn>
                </router-link>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      title: "WICE - Past Speakers",
      name1: "Prof Dr. I Made Sara, MP",
      name2: "Prof. Law Siong Hook, P.hD",
      name3: "Ciorstan Smark, PhD*",
      name4: "Prof Dr. Mhd Ikhwanuddin Bin Abdullah",
      univ1: "Lecturer at the Warmadewa University Foundation since 1985 – now assigned to the Faculty of Economics and Business. Since becoming a lecturer, he has served as Deputy Dean III of the Faculty of Economics and Business, Warmadewa University (1984-1987), as Deputy Dean and concurrently Assistant Dean I of the Faculty of Economics, Warmadewa University (1987-1990), as Deputy Dean of the Faculty of Economics, Warmadewa University (1990-1994), as Deputy Chancellor II of Warmadewa University (1998-2002), Chair of the Executive Class of the Faculty of Economics, Warmadewa University (2003-2005), and as Dean Faculty of Economics and Business, Warmadewa University from 2016-2023. In July 2023, he won the title of Professor in Economics. Many achievements, awards, and organizational experiences have been achieved and carried out, such as being active in the Indonesian Association of Economics Scholars in Denpasar Bali as Vice Chair II (2019-2021) and Deputy Chair of the Indonesian Association of Economics Scholars Denpasar Bali (2022 – present), as a Member of the Bali Province Wages Board for 2022 and 2023. Active as a Competency Assessor for the Human Resource Management Professional Certification Institute BALI ASEAN INTERNASIONAL, and actively joined the Indonesian Professional Assessors Association (AISPRO).",
      univ2: "Professor, Department of Economics, Faculty of Economics and Management, Universiti Putra Malaysia. Field Specialization Financial Economics and Applied Macroeconometrics Applied Panel Data Analysis and Time Series Analysis: Macro-financial linkages, Institutional quality, innovation, income inequality, Circular Economy – Sustainable Development Goals (Micro / Macro), FinTech Development",
      univ3: "Senior Lecturer Faculty of Business and Law, School of Business, Wollongong, Australia. Dr Ciorstan Smark is a Senior Lecturer in Accounting and Finance with over twenty years' experience at both undergraduate and postgraduate levels. Her publications cover a wide range of accounting, finance and business ethics issues, Masters Research or PhD student supervision.  ",
      univ4: "CHIEF EDITOR, The Journal of Sustainability Science and Management. Universiti Malaysia Terengganu, Malaysia. Dr. Mhd. Ikhwanuddin is currently working as Professor at Institute of Tropical Aquaculture, Universiti Malaysia Terengganu, Malaysia. He has completed his Ph.D. in Marine Biology from Universiti Malaysia Sarawak, Malysia. His main area of interest related to Animal and Veterinary Sciences, Molecular Sciences, and Environmental Sciences. His main area of expertise includes Crustacean, Seed production, Scylla, Hatchery technique, Banana shrimp, Crab mating, Enzyme activity, Reproduction, Probiotic, Fisheries, Hormone, White shrimp, Black tiger shrimp, Triploidy, Larviculture, Portunid Crab, Mud spiny lobster, Ovarian maturation, Maturation diets, Natural diet, Population dynamic, Breeding technology, Portunus pelagicus, Live food, Movement pattern, Artificial production, Artificial diet, and Catch information. He has published 32 research articles in journals contributed as author/co-author.",
    };
  }
};
</script>
    
    <style scoped>
.v-container.oo {
  background: #035295;
  max-width: 100%;
}
.v-container.oj {
  background: #035295;
  max-width: 100%;
  /* margin-left: auto;
      margin-right: auto; */
  /* padding-right: 100px;
      padding-left: 100px; */
}

.v-container.oj {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oj {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oj {
    max-width: 1140px;
  }
}

h1 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 5px;
}

h2 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
  
  
}

h3 {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: normal;
}

.v-img {
  width: 300px;
  height: 300px;
  border-radius: 100px;
}

.v-btn {
    width: 555px;
  height: 40px;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-top: 15px;
}

.v-card {
  background: #035295;
  width: 204px;
  height: 220px;
}

.v-card:hover {
  box-shadow: 1px 8px 20px #1b79cc;
  -webkit-transition: box-shadow 0.3s ease-in;
}

/* .v-img {
    border-radius: 10%;
  } */
</style>