import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/important',
    name: 'important',
    component: () => import('../views/ImportantDView.vue')
  },
  {
    path: '/scientific',
    name: 'scientific',
    component: () => import('../views/ScientificView.vue')
  },
  {
    path: '/conference',
    name: 'conference',
    component: () => import('../views/ConferenceView.vue')
  },
  {
    path: '/paperpub',
    name: 'paperpub',
    component: () => import('../views/PaperPubView.vue')
  },
  {
    path: '/callforpaper',
    name: 'callforpaper',
    component: () => import('../views/CallForPaperView.vue')
  },
  {
    path: '/abstract',
    name: 'abstract',
    component: () => import('../views/AbstractFormatView.vue')
  },
  {
    path: '/fullpaper',
    name: 'fullpaper',
    component: () => import('../views/FullPaperFormatView.vue')
  },
  {
    path: '/poster',
    name: 'poster',
    component: () => import('../views/PosterFormatView.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/regis',
    name: 'regis',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  {
    path: '/venue',
    name: 'venue',
    component: () => import('../views/VenueView.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/HistoryView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactUsView.vue')
  },
  {
    path: '/onlinesubs',
    name: 'onlinesubs',
    component: () => import('../views/OnlineSubsView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
