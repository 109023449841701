<template>
  <div id="ourJ">
    <v-container class="oo">
      <div class="text-center pt-5">
        <h1>{{ title }}</h1>
        <br />
      </div>
      <v-container class="oj">
        <v-row justify="center">
          <v-col cols="12" md="5" class="pb-5">
            <v-card class="pa-8 rounded-xl">
              <h2>{{ head1 }}</h2>
              <h3>{{ subHead1 }}</h3>
              <br />
              <hr />
              <div class="text-center mt-8">
                <router-link to="onlinesubs" custom v-slot="{ navigate }">
                  <v-btn variant="outlined" rounded @click="navigate" role="link">{{ action1 }}</v-btn>
                </router-link>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" class="pa-5">
            <v-card class="pa-8 rounded-xl">
              <h2>{{ head2 }}</h2>
              <h3>{{ subHead2 }}</h3>
              <br />
              <hr />
              <div class="text-center mt-8">
                <v-btn v-bind:href="linkWA" variant="outlined" rounded>{{ action2 }}</v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      title:
        "WICE - one of the most anticipated social science conferences of 2023",
      head1: "submit your article",
      head2: "minimum 10 pax (20 audience)",
      subHead1: "Online Submission",
      subHead2: "Be Audience",
      action1: "SUBMIT ARTICLE",
      action2: "REGISTRASION"
    };
  }
};
</script>
    
<style scoped>
.v-container.oo {
  background: #ffffff;
  max-width: 100%;
}
.v-container.oj {
  background: #ffffff;
  max-width: 100%;
}

.v-container.oj {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oj {
    max-width: 1000px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oj {
    max-width: 950px;
  }
}

h1 {
  color: #333;
  font-size: 35px;
  margin-bottom: 5px;
}

h2 {
  color: #333;
  font-size: 18px;
  margin-bottom: 5px;
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
}

h3 {
  font-weight: 700;
  color: #035295;
  font-size: 30px;
}

.v-btn:hover {
  background-color: #337cbd;
  color: #ffffff;
}

/* .v-btn:focus {
  background-color: #d6cda4;
}

.v-btn:active {
  background-color: #d6cda4;
} */
.v-btn {
  background-color: #035295;
  color: #f5f5f5;
  text-transform: none !important;
}

.v-card {
  width: 360px;
  height: 238px;
  border-radius: 30px;
  background: #fff;
}

.v-card:hover {
  box-shadow: 1px 8px 20px #5d5d5d;
  -webkit-transition: box-shadow 0.3s ease-in;
}
</style>