<template>
  <div id="ourS">
    <v-container class="ou">
      <v-container class="os">
        <v-row class="pa-5">
          <v-col cols="12" md="6">
            <div class="mt-15 mr-7">
              <h1>{{ title }}</h1>
              <h2 class="mt-4">{{ head1_1 }}</h2>
              <h2 class="mt-4">{{ head1_2 }}</h2>
              <h2 class="mt-4">{{ head1_3 }}</h2>
              <h2 class="mt-4">{{ head1_4 }}</h2>
              <h2 class="mt-4">{{ head1_5 }}</h2>
              <h2 class="mt-4">{{ head1_6 }}</h2>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="mt-15">
              <h1 style="color:#333">{{ title2 }}</h1>
              <v-img
                style="position: relative"
                src="@/assets/assetsWice/crossref.png"
                max-width="230"
                max-height="123"
              ></v-img>
              <h2 class="mt-4">{{ head2_1 }}</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
      
      <script>
export default {
  data() {
    return {
      title: "Paper Publication Opportunity",
      head1_1: "Academic conference Proceedings and Publication",
      head1_2:
        "The Abstracts of all accepted papers will be published in the Conference Abstract Book with ISBN Number. Full papers will have publication opportunities in various Indexed International Journals, including SCOPUS, Web of Science, DOAJ, and many more, or as a Book Chapter.",
      head1_3:
        "• Each Paper will be assigned Digital Object Identifier (DOI) from Cross Ref.",
      head1_4:
        "• The abstract book/proceedings shall be submitted to Google Scholar for Indexing.",
      head1_5:
        "• The Conference Proceedings will be published with an ISBN Number.",
      head1_6:
        "• All papers have publication opportunities in various indexed international journals.",

      title2: "Plagiarism Policy & Publication Ethics",
      head2_1:
        "3rd Warmadewa International Conference  of Economic 2023  International conference on green economy, sustainable business and climate change follows strict anti-plagiarism policies and, as such, checks every submission for plagiarism using Crossref Similarity Check Powered by iThenticate. All articles submitted to the conference first undergo a plagiarism check before being sent to our scientific committee for review. The submission will be automatically rejected at any time if found plagiarized. If you’d like to find out more information about the iThenticate software, click on the following link: https://www.crossref.org/services/similarity-check/",
      head2_2:
        "• Policy and Governance for a Greener Economy: Enabling Transition and Transformation",
      head2_3:
        "• Green Jobs and Skills: Empowering the Workforce for a Sustainable Future",
      head2_4:
        "• Sustainable Cities and Communities: Designing Urban Spaces for Green Living"
    };
  }
};
</script>
      
      <style scoped>
.v-container.ou {
  background: #ffffff;
  max-width: 100%;
}

.v-container.os {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.v-container.os {
  padding: 10px;
}

/* .v-img {
  margin-top: 170px;
} */

/* Tablet */
@media (min-width: 600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

h1 {
  color: #035295;
  font-size: 24px;
}

h2 {
  font-weight: normal;
  font-size: 16px;
  color: #5d5d5d;
  text-align: justify;
  text-justify: inter-word;
}
</style>