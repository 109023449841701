<template>
  <div id="hero">
    <v-container class="hh">
      <v-container class="he">
        <v-row class="pt-0 text-center">
          <v-col cols="12" md="12">
            <div class="ma-0 animate__animated animate__backInLeft">
              <div>
                <v-col>
                  <h2>
                    <a href="/">
                      <v-icon style="margin-bottom: 5px; font-size: 20px" icon="mdi-home-outline"></v-icon>
                    </a>
                    / {{ head1 }}
                  </h2>
                </v-col>
              </div>
              <h1>{{ head1 }}</h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
    
<script>
import imgHero from "@/assets/assetsWice/hero2.png";

export default {
  data() {
    return {
      head1: "About Conference",
      head2:
        "“International conference on green economy, sustainable business and ",
      head3: "climate change”",
      subHead:
        "National Journal Publication Solutions (ISSN and SINTA Accredited) and Reputable International Journals",
      linkWA: "https://wa.link/3bn2e2",
      imgHero
    };
  }
};
</script>
    
    <style scoped>
a:link {
  text-decoration: none;
}

a {
  color: #f7f8fa;
  font-size: 9px;
}
h1 {
  color: #eee;
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
}
h2 {
  color: #eee;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 991px) {
  .a {
    font-size: 2rem;
  }
  h4 {
    font-size: 1rem;
    color: rgb(14, 13, 13);
    /* background-image: url("@/assets/logo.svg"); */
  }
}

.v-container.hh {
  background: url("@/assets/assetsWice/hero2.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 574.813px;
  max-width: 100%;
}
.v-container.he {
  width: 1006px;
  height: 352px;
  margin-top: 191px;
  max-width: 100%;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.he {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.he {
    max-width: 1281px;
  }
}
.v-btn:hover {
  background-color: #b10801;
  color: #ffffff;
}

.v-btn:focus {
  background-color: #d6cda4;
}

.v-btn:active {
  background-color: #d6cda4;
}
.v-btn {
  color: #b10801;
  text-transform: none !important;
}
</style>