<template>
  <v-app>
    <NavBarwice />
  <HeroWice />
  <ExpandYour />
  <AddWice />
  <ConferenceThemes />
  <WicePast />
  <CompetitiveAadvantages />
  <PaperPublication />
  <IndexingWice />
  <SubmitArticle />
  <ContactUs />
  <FooterWice/>
</v-app>
</template>

<script>
import { defineComponent } from 'vue';
import NavBarwice from '@/components/NavBarwice.vue';
import HeroWice from '@/components/HeroWice.vue';
import ExpandYour from '@/components/ExpandYour.vue';
import AddWice from '@/components/AddWice.vue';
import ConferenceThemes from '@/components/ConferenceThemes.vue';
import WicePast from '@/components/WicePast.vue';
import CompetitiveAadvantages from '@/components/CompetitiveAadvantages.vue';
import PaperPublication from '@/components/PaperPublication.vue';
import IndexingWice from '@/components/IndexingWice.vue';
import SubmitArticle from '@/components/SubmitArticle.vue';
import ContactUs from '@/components/ContactUs.vue';
import FooterWice from '@/components/FooterWice.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    NavBarwice,
   HeroWice, 
   ExpandYour,
   AddWice,
   ConferenceThemes,
   WicePast,
   CompetitiveAadvantages,
   PaperPublication,
   IndexingWice,
   SubmitArticle,
   ContactUs,
   FooterWice
  },
});
</script>
