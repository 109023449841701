<template>
  <div id="ourS">
    <v-container class="ou">
      <v-container class="os">
        <v-row class="pa-5">
            <v-col cols="12" md="12">
                <h1>{{ head1_1 }}</h1>
            </v-col>
          <v-col cols="12" md="6">
            <div class="mt-5">
              <h2 class="mt-0">{{ head2_1 }}</h2>
              <h2 class="mt-5">{{ head2_2 }}</h2>
              <h2 class="mt-5">{{ head2_3 }}</h2>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              style="position: relative"
              src="@/assets/assetsWice/6.png"
              max-width="609"
              max-height="457"
            ></v-img>
            <!-- <v-img src="@/assets/1.png" max-height="70"></v-img> -->
            <!-- <v-row>
              <v-col cols="1" ls="10" md="10">
                <v-row align="start">
                  <v-img  src="@/assets/1.png" max-height="70" ></v-img>
                <h2>DOAJ Indexing Assistance</h2> </v-row>
                <v-row align="start">
                  <v-img  src="@/assets/1.png" max-height="70" ></v-img>
                <h2>DOAJ Indexing Assistance</h2> </v-row>
    
                <v-img src="@/assets/2.png" max-height="70"></v-img>
                <v-img src="@/assets/3.png" max-height="70"></v-img>
                <v-img src="@/assets/4.png" max-height="70"></v-img>
                <v-img src="@/assets/5.png" max-height="70"></v-img>
                <v-img src="@/assets/6.png" max-height="70"></v-img>
              </v-col>
              <v-col cols="2" ls="10" md="10">
              <h2>Research Program and Study Center</h2>
              <h2>Develop Open Journal System</h2>
              <h2>Data Processing</h2>
              <h2>Turnitin and IThenticate</h2>
              <h2>Parafrase and Copyediting</h2>
              </v-col>
              
    
            </v-row>-->
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      head1_1: "Competitive Advantages of The 3rd Warmadewa International Conference of Economy",
      head2_1: "The 3rd WICE 2023 Conference will be organized by the Faculty of Economic and Business, Warmadewa University that aims to bring together the academicians and stakeholders in economic and business throughout the region and internationally.",
      head2_2:
        "All manuscripts submitted to The 3rd WICE 2023 conference undergo a selection and assessment process by the Organizer. The manuscripts will be published on SINTA, Scopus, and International Journals. The conference applies this process as the preliminary selection to determine whether the manuscripts have conformed to the conference’s submission guidelines, focus and scope and whether they are of excellent academic quality. The language check also becomes essential to our screening at this stage. Authors must ensure that the conference editors and reviewers fully understand the academic content of their paper.",
      head2_3:
        "We have collaborated with many co-organizer to retain our quality in providing a platform for global participants to share their research findings, ideas and experiences with academics, scholars, researchers and the stakeholders through The 3rd WICE 2023 Conference.",
    };
  }
};
</script>
    
    <style scoped>
.v-container.ou {
  background: #ffffff;
  max-width: 100%;
}

.v-container.os {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.v-container.os {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

.v-img {
    margin-left: 50px;
}

h1 {
  font-size: 35px;
}

h2 {
  font-weight: normal;
  font-size: 16px;
  color: #5d5d5d;
  text-align: justify;
  text-justify: inter-word;
}
</style>